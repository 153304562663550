export default [
  {
    value: 'email',
    label: 'Email / Password'
  },
  {
    value: 'google',
    label: 'Google'
  },
  {
    value: 'facebook',
    label: 'Facebook'
  },
  {
    value: 'apple',
    label: 'Apple'
  },
  {
    value: 'x',
    label: 'X'
  }
];
