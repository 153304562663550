export default [
  {
    value: 'admin',
    label: 'Administrator'
  },
  {
    value: 'user',
    label: 'User'
  }
];
