import * as admin from './admin';
// import * as user from './user';
import * as anonymous from './anonymous';

const config = {
  admin,
  //   user,
  anonymous
};

export default config;
